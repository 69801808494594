<template>
    <div>
        <section id="basic-tabs-components">
            <div class="row match-height">
                <div class="col-12">
                    <ul class="nav nav-tabs" role="tablist">
                        <li 
                            class="nav-item"
                            :class="`${accountType == tab ? 'active' : ''}`"
                            v-for="(tab, i) in tabKeys" 
                            :key="i" 
                            @click="onClickTab(tab)"
                            type="button"
                        >
                            <a :class="`${accountType == tab ? 'active' : ''}`" class="nav-link"  data-bs-toggle="tab"  aria-controls="home" role="tab" aria-selected="true">{{ tabs[tab] }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'TabComponent',
    props: {
        tabs: Object,
        routeName: {
            default: 'accounts-list'
        }
    },
    computed: {
        tabKeys() {
            return this.tabs ? Object.keys(this.tabs) : []
        },
        accountType() {
            return this.$route.params.accountType
        }
    },
    methods: {
        onClickTab(tabName) {
            this.$router.push({name: this.routeName, 
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId,
                    accountType: tabName
                }
            });

            setTimeout( () => {
                this.$emit('onClickTab',tabName)
            }, 100)
        }
    }
}
</script>
